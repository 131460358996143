
























.footer-logo {
  width: 100%;
  height: auto;
}
